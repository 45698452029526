<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    Каталог вакансий
                </b-card-title>
                <b-button 
                    pill 
                    variant="primary" 
                    :to="{ name: 'admin-vacancies-add', params: { employments: employments }}"
                > Добавить вакансию </b-button>
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='7' :rows='5'>
            
            </b-skeleton-table>
            
            <b-table-simple v-else class="font-small-3 text-black" responsive>
                <b-thead>
                    <b-tr>
                        <b-td colspan="2">#</b-td>
                        <b-td>Ссылка на hh.ru</b-td>
                        <b-td>Заголовок</b-td>
                        <b-td>Описание</b-td>
                        <b-td>Заработная плата</b-td>
                        <b-td>Дата создания</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(vacancy, index) in items" :key="vacancy.id">
                        <b-td class="text-black font-weight-bolder">{{ vacancy.id }}</b-td>
                        <b-td>
                            <b-avatar 
                                rounded
                                :src="vacancy.media.urls.thumb"
                            />
                        </b-td>
                        <b-td class="text-black font-weight-bolder">
                            <template >
                                <div class="d-flex justify-content-between" style="align-items: center;"> 

                                <b-link :href="vacancy.hh_url" class="text-ellipsis ">{{start_and_end(vacancy.hh_url)}}</b-link>
                                    <div v-ripple @click="copyUrl($event, vacancy.id, vacancy.hh_url)" style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                                    </div>
                                </div>
                            </template>
                        </b-td>
                        
                        <b-td class="text-black font-weight-bolder">{{ vacancy.header }}</b-td>
                        <b-td class="text-black">{{ vacancy.description }}</b-td>
                        <b-td class="text-black">от {{ vacancy.pay_from.formatMoney(2," "," ") }} до {{ vacancy.pay_to.formatMoney(2," "," ") }}</b-td>
                        <b-td class="text-black">{{ vacancy.date }} {{ vacancy.time }}</b-td>                      
                        <b-td>
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item :to="{ name: 'admin-vacancies-update', params: { vacancy: vacancy, employments: employments } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(vacancy, index)">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                    <div ref="container" class="wordwrap">
                        <span class="text-capitalize font-small-2" style="opacity: 0.55;"></span>
                    </div>
                </b-tbody>
            </b-table-simple>
            <b-card-body class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ total_count }}</span>

                <b-pagination-nav 
                    :value="current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="last_page" 
                    use-router 
                ></b-pagination-nav>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                is_loading: true,
                items: [],
                current_page: 0,
                has_more_pages: false,
                last_page: 0,
                total_count: 0,
                employments: [],
            }
        },
        methods: {
            
            preferDeleteAction( vacancy, index ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить вакансию '${vacancy.header}'?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("vacancies/delete", {
                            id: vacancy.id
                        }).then( rsp => {
                            this.items.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                title: vacancy.header,
                                text: 'Вакансия успешно удалена',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
                
            },
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },
            
            get() {
                this.$request.get("vacancies/list", this.$route.query ).then( rsp => {

                    this.items = rsp.items;
                    this.employments = rsp.employments;
                    this.total_count = rsp.total_count;
                    this.current_page = rsp.current_page;
                    this.last_page = rsp.last_page;
                    this.is_loading = false;
                });
            },

              start_and_end(str, start = 10, end = 10) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

            copyUrl( args, id, url) {
                
                let container = this.$refs.container;
                this.$copyText( url, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Скопировано.',
                      text: 'Ссылка успешно скопирована в буфер обмена.',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },

        },
        components: {
            
        },
        watch: {
            "$route.query" : function () {
                this.get();
            }
        },
        mounted() {
            this.is_loading = true;
            this.get();
        }

    }

</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>